@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body
{
    background-color: #fff;
    width: 100%;
    height: 100vh;
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 0.1em;
}

button
{
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 18px;
    background-color: #d40e0e;
    color: #fff;
    border: none;
    cursor: pointer;
    letter-spacing: 0.1em;
}

button:hover
{
    background-color: #970d0d;
}

.popup
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/back.jpg');
    background-color: #d40e0e;
    background-size: cover;
    background-position: center; 
    z-index: 1000;
    display: none;
  	align-items: center;
    justify-content: center;
}

.pop_box
{
    width: 60%;
    height: auto;
    padding: 2%;
    text-align: center;
}

.pop_box img
{
    width: 50%;
    max-width: 300px;
    height: auto;
    margin-bottom: 2%;
}

.pop_box h1
{
    font-size: 4vw;
    color: #fff;
    margin: 0;
}

.pop_box p
{
    font-size: 3vw;
    color: #fff;
    margin: 0;
}

.areaMain
{
    width: 100%;
    height: 100%;
}

.areaVideo
{
    width: 75%;
    height: 100vh;
    float: left;
    display: flex;
  	align-items: center;
    justify-content: center;
    background: url('../images/back.jpg');
    background-color: #d40e0e;
    background-size: cover;
    background-position: center; 
    position: relative;      
}

.centerElement
{
    width: 60%;
}

.logo
{
    width: 30%;
    max-width: 200px;
    height: auto;
}

#logos
{
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}

#vid
{
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
    height: 0;
}

#vid iframe
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.help_info
{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.2vw;
    margin: 0;
    margin-top: 2%;
}

.areaChat
{
    width: 25%;
    height: 100vh;
    float: right;
    position: relative;
    margin: 0;
    padding: 0;
}

.areaChat iframe
{
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}
  
@media screen and (max-width:800px) {

    
    .areaVideo
    {
        width: 100% !important;
        height: 40vh;
        display: block;
        border: none;
    }

    #logos
    {
        display: none !important;
    }

    button
    {
        display: none !important;
    }

    .centerElement
    {
        width: auto;
        height: 100%;
        background-color: #eef1f4;
    }

    #vid
    {
        position: relative;
        padding-bottom: 0%; /*panorámico*/
        padding-top: 0px;
        height: 100%;
        margin-top: 0;
    }

    #vid iframe
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .help_info
    {
        display: none;
    }
    
    .areaChat
    {
        width: 100%;
        height: 60vh;
        display: block !important;
    }

}
